import { createTheme } from '@mui/material'
import { COLORS } from '../constants'

export const muiTheme = createTheme({
  typography: {
    body2: {
      fontWeight: 300,
      fontSize: '1rem',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledWarning: {
          backgroundColor: COLORS.WARNING_BACKGROUND,
        },
      },
    },
  },
})
