import { Box } from '@mui/material'
import React from 'react'
import Loading from '../loading/Loading'

const FullscreenLoading: React.FC = () => {
  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      <Loading />
    </Box>
  )
}
export default FullscreenLoading
