import React, { useEffect, useState } from 'react'
import { Box, Button, Select, styled } from '@mui/material'
import { SelectProps } from '@mui/material/Select'
import { useApiDataContext } from '../../contexts/ApiDataContext'
import CheckinForm from './components/checkinForm/CheckinForm'
import 'normalize.css'
import { useTranslation } from 'react-i18next'
import useQuery from '../../hooks/useQuery'
import { useRegisterFormContext } from '../../contexts/RegisterFormContext'
import RegisterForm from './components/registerForm/RegisterForm'
import ErrorComponent from '../../components/error/Error'
import { QueryParams } from '../../App'
import LanguageSelector from '@/components/languageSelector/LanguageSelector'

export enum CheckinPages {
  Checkin = 'Checkin',
  Registerform = 'Registerform',
}

export const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'page',
})<
  SelectProps & {
    page: string
  }
>(({ page }) => ({
  height: '36.5px',
  width: '101.5px',
  color: `${page === CheckinPages.Checkin ? 'white' : '#1976d2'}`,
  '& .MuiSvgIcon-root': {
    color: `${page === CheckinPages.Checkin ? 'white' : '#1976d2'}`,
  },
  // outline: "none"
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))

function Checkin() {
  const {
    state: { apiData, checkinError: apiDataError },
    methods: { fetchApiData },
    dispatch,
  } = useApiDataContext()
  const {
    updateGuest,
    state: { guest },
    resetSteps,
  } = useRegisterFormContext()
  const { queryParams } = useQuery()
  const { i18n, t } = useTranslation()

  const [page, setPage] = useState<CheckinPages>(CheckinPages.Checkin)

  useEffect(() => {
    if (apiData) {
      if (!guest.reserveId) {
        updateGuest({ reserveId: apiData.id })
      }
    }
  }, [apiData, guest.reserveId, updateGuest])

  useEffect(() => {
    if (!queryParams) return
    const forceDefaultPageParam = queryParams[QueryParams.ForceDefaultPage]
    if (forceDefaultPageParam) {
      if (forceDefaultPageParam === CheckinPages.Registerform.toLowerCase()) {
        ;(async () => {
          await fetchApiData()
          setPage(CheckinPages.Registerform)
        })()
      }
      if (forceDefaultPageParam === CheckinPages.Checkin.toLowerCase()) {
        setPage(CheckinPages.Checkin)
      }
    }
  }, [fetchApiData, i18n.language, queryParams])

  if (apiDataError) {
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <ErrorComponent title={apiDataError.title} description={apiDataError.description}>
          {apiDataError.hideTryAgainButton ? null : (
            <Button
              variant="outlined"
              onClick={() => {
                resetSteps()
                dispatch({
                  type: 'setError',
                  payload: undefined,
                })
                setPage(CheckinPages.Checkin)
              }}
            >
              {t('error.tryAgainButton')}
            </Button>
          )}
        </ErrorComponent>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100vw',
        maxWidth: '100%',
        position: 'relative',
      }}
    >
      <LanguageSelector />
      {page === CheckinPages.Checkin ? (
        <CheckinForm setPage={setPage} />
      ) : page === CheckinPages.Registerform ? (
        <RegisterForm setPage={setPage} />
      ) : null}
    </Box>
  )
}
export default Checkin
