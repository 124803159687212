import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import SelectOptionCard from '../../components/SelectOptionCard'
import CardForm, { CardFormData } from './sections/CardForm'
// import BizumImg from '../../../../assets/paymentOptions/bizum.svg'
// import KlarnaImg from '../../../../assets/paymentOptions/klarna.svg'
import payCometLogo from '../../../../assets/paymentLogos/paycomet.png'
import visaLogo from '../../../../assets/paymentLogos/visa.svg'
import mastercardLogo from '../../../../assets/paymentLogos/mastercard.svg'
// import bizumLogo from '../../../../assets/paymentLogos/bizum.svg'
// import klarnaLogo from '../../../../assets/paymentLogos/klarna.svg'
// import pcidssLogo from '../../../../assets/paymentLogos/pcidss.svg'
import { t } from 'i18next'
import useCardPayment, { CardPaymentFailedError } from '@/hooks/useCardPayment'
import { usePaymentContext } from '@/contexts/PaymentContext'
import { useApiDataContext } from '@/contexts/ApiDataContext'

const PaymentForm = ({
  priceToPay,
  paidTypeId,
  onPaymentError,
  onPaymentSuccess,
}: {
  priceToPay?: string
  paidTypeId: string
  onPaymentError?: (error: CardPaymentFailedError) => void
  onPaymentSuccess?: () => void
}) => {
  const { makeCardPayment, paymentLoading } = useCardPayment()
  const {
    state: { apiData },
  } = useApiDataContext()
  const {
    state: { useValidCardIdIfExists, apiPaymentData },
    openPaymentIframe,
    closePaymentIframe,
    setShowPartialPayment,
    fetchPaymentData,
  } = usePaymentContext()

  const { validCardId, paymentTypeCardId } = apiPaymentData!
  const { id, serverPath } = apiData!

  const handleCardSubmit = (data: CardFormData) => {
    makeCardPayment({
      data:
        validCardId && useValidCardIdIfExists
          ? {
              price: data.price,
              reserveId: id,
              paidTypeId,
              paymentTypeId: paymentTypeCardId,
              cardId: validCardId,
              cardCVV: data.cvc,
            }
          : {
              price: data.price,
              cardCVV: data.cvc!,
              cardName: data.ccname,
              cardNumber: data.cardnumber,
              cardExpirationDate: data['exp-date'],
              reserveId: id,
              paidTypeId,
              paymentTypeId: paymentTypeCardId,
            },
      onAuthorizeIframe: (url) => {
        openPaymentIframe(url)
      },
      serverPath,
      onError: (error) => {
        if (error instanceof CardPaymentFailedError) {
          setShowPartialPayment(true)
        }
        closePaymentIframe()
        onPaymentError?.(error)
      },
      onSuccess: async () => {
        closePaymentIframe()
        await fetchPaymentData()
        onPaymentSuccess?.()
      },
    })
  }

  return (
    <Stack gap="1rem">
      <Typography variant="h6" textAlign="center">
        {t('payment.Select_the_payment_method')}
      </Typography>
      <Stack display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="1rem" textAlign="center">
        <SelectOptionCard
          selected
          sx={{
            display: 'grid',
            placeItems: 'center',
            height: '5rem',
            gridColumn: '2 / 3',
          }}
        >
          <Typography fontWeight={600} fontSize={'1.1rem'} letterSpacing={0} lineHeight={'1.2rem'}>
            {t('payment.Bank_card')}
          </Typography>
        </SelectOptionCard>
        {/* <SelectOptionCard
          sx={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <img src={BizumImg} />
        </SelectOptionCard>
        <SelectOptionCard
          sx={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <img src={KlarnaImg} />
        </SelectOptionCard> */}
      </Stack>
      <CardForm priceToPay={priceToPay} onSubmit={handleCardSubmit} loading={paymentLoading} />

      <Box
        mt="1rem"
        sx={{
          '& > *': {
            display: 'inline-block',
          },
          placeItems: 'center',
        }}
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
      >
        <img src={payCometLogo} width="150px" alt="Paycomet" style={{ objectFit: 'contain' }} />
        <img src={visaLogo} width="85px" alt="Visa" style={{ objectFit: 'contain' }} />
        <img src={mastercardLogo} width="80px" alt="Mastercard" style={{ objectFit: 'contain' }} />
        {/* <img src={bizumLogo} /> */}
        {/* <img src={klarnaLogo} /> */}
        {/* <img src={pcidssLogo} /> */}
      </Box>
    </Stack>
  )
}

export default PaymentForm
