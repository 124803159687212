import React from 'react'
import { Box, BoxProps, Card, Typography } from '@mui/material'
import { t } from 'i18next'
import { TbAlertTriangleFilled } from 'react-icons/tb'
import { COLORS } from '../../styles/constants'

const ErrorAlert = ({
  title,
  description,
  ...restBoxProps
}: { title?: string; description?: string } & BoxProps) => {
  return (
    <Box textAlign="center" {...restBoxProps}>
      <TbAlertTriangleFilled fontSize="4rem" color={COLORS.ERROR} />
      <Card sx={{ backgroundColor: COLORS.ERROR, color: 'white', padding: '1rem' }}>
        <Typography variant="h6">{title || t('payment.error.Payment_failed.title')}</Typography>
        <Typography mt=".5rem">
          {description || t('payment.error.Payment_failed.description')}
        </Typography>
      </Card>
    </Box>
  )
}

export default ErrorAlert
