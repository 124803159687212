import { Box, BoxProps, IconButton, Modal, ModalProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
interface Props extends Omit<ModalProps, 'onClose'> {
  cardProps?: BoxProps
  showCloseBtn?: boolean
  onClose?: () => void
}
const CustomModal: React.FC<Props> = ({
  showCloseBtn,
  cardProps: { sx, ...cardProps } = {},
  onClose,
  ...props
}) => {
  const handleClose = () => {
    onClose?.()
  }

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
      {...props}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          maxWidth: '90vw',
          bgcolor: 'background.paper',
          borderRadius: '.5rem',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          textAlign: 'center',
          ...sx,
        }}
        {...cardProps}
      >
        {showCloseBtn && (
          <IconButton
            sx={{
              position: 'absolute',
              top: '.5rem',
              right: '.5rem',
            }}
            size="small"
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {props.children}
      </Box>
    </Modal>
  )
}
export default CustomModal
