import React from 'react'
import PaymentForm from '../../sections/paymentForm/PaymentForm'
import { usePaymentContext } from '@/contexts/PaymentContext'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { APP_ROUTES } from '@/config/routes.config'

const PaymentPartial = () => {
  const {
    state: { apiPaymentData },
    setError,
  } = usePaymentContext()
  const { paidTypeStandardId } = apiPaymentData!

  const { navigate } = useAppNavigate()

  return (
    <PaymentForm
      paidTypeId={paidTypeStandardId}
      onPaymentError={(e) => {
        setError({
          message: e.message,
        })
        navigate({
          pathname: APP_ROUTES.PAYMENT,
          replace: true,
        })
      }}
      onPaymentSuccess={() => {
        navigate({
          pathname: APP_ROUTES.PAYMENT,
          replace: true,
        })
      }}
    />
  )
}

export default PaymentPartial
