import { QueryParams } from '@/App'
import useQuery from '@/hooks/useQuery'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { setDefaultOptions } from 'date-fns'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enLocale from 'date-fns/locale/en-GB'
import esLocale from 'date-fns/locale/es'
import itLocale from 'date-fns/locale/it'
import frLocale from 'date-fns/locale/fr'

export enum Languages {
  Es = 'es',
  En = 'en',
  It = 'it',
  Fr = 'fr',
}

const localeMap = {
  en: enLocale,
  es: esLocale,
  it: itLocale,
  fr: frLocale,
}

const LanguageContext = createContext<{
  language: Languages
  changeLanguage: (language: Languages) => void
}>({} as any)

const LanguageContextProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState<Languages>(Languages.Es)
  const { queryParams, setURLQueryParams } = useQuery()
  const paramLang = queryParams?.[QueryParams.LangId] as Languages

  useEffect(() => {
    const defaultLang = paramLang || (i18n.language as Languages)
    i18n.changeLanguage(defaultLang)
    setLanguage(defaultLang)
    setDefaultOptions({
      locale: localeMap[defaultLang as keyof typeof localeMap],
    })
  }, [i18n, paramLang])

  const changeLanguage = (language: Languages) => {
    i18n.changeLanguage(language)
    setLanguage(language)
    setDefaultOptions({
      locale: localeMap[language as keyof typeof localeMap],
    })
    setURLQueryParams({
      langid: language,
    })
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={localeMap[i18n.language as keyof typeof localeMap]}
      >
        {children}
      </LocalizationProvider>
    </LanguageContext.Provider>
  )
}
export default LanguageContextProvider
export const useLanguageContext = () => useContext(LanguageContext)
