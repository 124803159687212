import { useLocation, useNavigate } from 'react-router-dom'

export const useAppNavigate = () => {
  const vanillaNavigate = useNavigate()
  const { search } = useLocation()

  const navigate = ({ pathname, replace }: { pathname: string; replace?: boolean }) => {
    vanillaNavigate(
      {
        pathname,
        search,
      },
      {
        replace,
      }
    )
  }

  const goBack = () => {
    vanillaNavigate(-1)
  }

  return {
    navigate,
    goBack,
  }
}
