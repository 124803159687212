import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { validateOnlyInt, validateOnlyNumber } from '../../utils/validators'

interface Props {
  onlyInt?: boolean
}

const TextFieldNumber = React.forwardRef<any, Props & TextFieldProps>(
  ({ onlyInt, ...props }, ref) => {
    return (
      <TextfieldComponent
        inputRef={ref}
        onKeyPress={(e) => {
          const key = e.key
          if (!(onlyInt ? validateOnlyInt(key) : validateOnlyNumber(key))) {
            e.preventDefault()
          }
        }}
        {...props}
      />
    )
  }
)

TextFieldNumber.displayName = 'TextFieldNumber'

const TextfieldComponent = ({ sx, ...props }: TextFieldProps) => {
  return (
    <TextField
      sx={{
        '& input[type=number]::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          margin: 0,
        },
        ...sx,
      }}
      {...props}
    />
  )
}
export default TextFieldNumber
