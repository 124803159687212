import React, { useEffect, useMemo, useState } from 'react'
import { ButtonProps, Stack, Typography } from '@mui/material'
import SelectOptionCard from '../../components/SelectOptionCard'
import { t } from 'i18next'
import { FatButton } from '../../../../components/fatButton/FatButton'
import DepositOptionCard from './components/DepositOptionCard'
import { usePaymentContext } from '../../../../contexts/PaymentContext'
import PaymentsIcon from '@mui/icons-material/Payments'
import { dateFormatter } from '../../../../utils/dateFormatter.utils'
import ErrorAlert from '../../../../components/errorAlert/ErrorAlert'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { APP_ROUTES } from '@/config/routes.config'

enum PAYMENT_OPTIONS {
  BAIL = 'BAIL',
  ADVANCE = 'ADVANCE',
  TOTAL = 'TOTAL',
  PARTIAL = 'PARTIAL',
}

const PAYMENT_OPTIONS_ROUTE_MAP: { [key in PAYMENT_OPTIONS]: string } = {
  [PAYMENT_OPTIONS.ADVANCE]: APP_ROUTES.PAYMENT_ADVANCE,
  [PAYMENT_OPTIONS.BAIL]: APP_ROUTES.PAYMENT_BAIL,
  [PAYMENT_OPTIONS.TOTAL]: APP_ROUTES.PAYMENT_TOTAL,
  [PAYMENT_OPTIONS.PARTIAL]: APP_ROUTES.PAYMENT_PARTIAL,
}

const PaymentButton = ({
  disabled,
  bailPaid,
  bailRefund,
  restPaid,
  bailPrice,
  ...restProps
}: {
  disabled?: boolean
  restPaid: boolean
  bailPaid: boolean
  bailRefund: boolean
  bailPrice?: string
} & ButtonProps) => {
  const fullyPaid = restPaid && (bailPaid || !bailPrice)
  const depositPendingToRefund = bailPaid && !bailRefund

  const text = useMemo(() => {
    if (depositPendingToRefund && fullyPaid) {
      return t('payment.Refund_pending')
    }
    if (fullyPaid) {
      return t('payment.Nothing_pending')
    }
    return t('payment.Continue_with_payment')
  }, [depositPendingToRefund, fullyPaid])

  return (
    <FatButton disabled={disabled} {...restProps}>
      {text}
    </FatButton>
  )
}

const PaymentHome = () => {
  const { navigate } = useAppNavigate()
  const {
    state: { apiPaymentData, error },
    partialPaymentEnabled,
    clearError,
  } = usePaymentContext()

  const {
    advancePrice,
    pendingPrice,
    bailPrice,
    advancePaid,
    bailPaid,
    restPaid,
    bailRefund,
    bailToRefundPrice,
    bailRefundDate,
    restPaymentDate,
  } = apiPaymentData!

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PAYMENT_OPTIONS>()

  useEffect(() => {
    return () => {
      clearError()
    }
  }, [])

  const handleSelectPartial = () => {
    if (restPaid) return
    setSelectedPaymentOption(PAYMENT_OPTIONS.PARTIAL)
  }

  const handleSelectAdvance = () => {
    if (advancePaid) return
    setSelectedPaymentOption(PAYMENT_OPTIONS.ADVANCE)
  }

  const handleSelectTotal = () => {
    if (restPaid) return
    setSelectedPaymentOption(PAYMENT_OPTIONS.TOTAL)
  }

  const handleSelectDeposit = () => {
    if (bailPaid) return
    setSelectedPaymentOption(PAYMENT_OPTIONS.BAIL)
  }

  const handleContinue = () => {
    if (selectedPaymentOption) {
      navigate({ pathname: PAYMENT_OPTIONS_ROUTE_MAP[selectedPaymentOption] })
    }
  }

  return (
    <>
      {!!error && <ErrorAlert description={error} marginBottom="1rem" />}
      <Stack gap="1rem">
        <Typography variant="h6">
          {t('payment.Select_the_type_of_payment_you_want_to_make')}
        </Typography>

        <Stack mt="1rem" gap="1rem" paddingBottom="5rem">
          {partialPaymentEnabled && (
            <SelectOptionCard
              onClick={handleSelectPartial}
              selected={selectedPaymentOption === PAYMENT_OPTIONS.PARTIAL}
            >
              <SelectOptionCard.Title>{t('payment.Partial_payment')}</SelectOptionCard.Title>
              <Typography mt=".5rem">{t('payment.Partial_payment-description')}</Typography>
              <SelectOptionCard.Footer>
                <SelectOptionCard.Value>{t('payment.Partial_payment')}</SelectOptionCard.Value>
              </SelectOptionCard.Footer>
            </SelectOptionCard>
          )}

          {advancePrice && (
            <SelectOptionCard
              onClick={handleSelectAdvance}
              selected={selectedPaymentOption === PAYMENT_OPTIONS.ADVANCE}
            >
              <Stack direction="row" gap="1rem" justifyContent="space-between">
                <SelectOptionCard.Title>{t('payment.Advance')}</SelectOptionCard.Title>
                {!advancePaid && (
                  <SelectOptionCard.Label color="warning">
                    {t('payment.Required_to_confirm_reservation')}
                  </SelectOptionCard.Label>
                )}
              </Stack>
              <Typography mt=".5rem">{t('payment.Advance-description')}</Typography>
              <SelectOptionCard.Footer>
                {advancePaid ? (
                  <SelectOptionCard.Label color="success">
                    <PaymentsIcon />
                    {t('payment.Paid')}
                  </SelectOptionCard.Label>
                ) : (
                  <SelectOptionCard.Value>{`${advancePrice}€`}</SelectOptionCard.Value>
                )}
              </SelectOptionCard.Footer>
            </SelectOptionCard>
          )}

          <SelectOptionCard
            onClick={handleSelectTotal}
            selected={selectedPaymentOption === PAYMENT_OPTIONS.TOTAL}
          >
            <SelectOptionCard.Title>
              {t('payment.Total_payment_of_reservation')}
            </SelectOptionCard.Title>
            <Typography>{t('payment.Total_payment_of_reservation-description')}</Typography>
            <SelectOptionCard.Footer>
              {restPaid && (
                <SelectOptionCard.Label color="success">
                  <PaymentsIcon />
                  {t('payment.Paid')}
                </SelectOptionCard.Label>
              )}
              {!restPaid && (
                <>
                  {restPaymentDate && (
                    <SelectOptionCard.Label>
                      <PaymentsIcon />
                      {t('payment.Will_be_charged_on_date', {
                        date: dateFormatter.formateToLocaleShort(restPaymentDate),
                      })}
                    </SelectOptionCard.Label>
                  )}
                  <SelectOptionCard.Value>{`${pendingPrice}€`}</SelectOptionCard.Value>
                </>
              )}
            </SelectOptionCard.Footer>
          </SelectOptionCard>

          {!!bailPrice && (
            <DepositOptionCard
              onClick={handleSelectDeposit}
              selected={selectedPaymentOption === PAYMENT_OPTIONS.BAIL}
              bailPrice={bailPrice}
              bailPaid={bailPaid}
              bailRefund={bailRefund}
              bailToRefundPrice={bailToRefundPrice}
              bailRefundDate={bailRefundDate}
            />
          )}
          <PaymentButton
            disabled={!selectedPaymentOption}
            bailPaid={bailPaid}
            restPaid={restPaid}
            bailRefund={bailRefund}
            bailPrice={bailPrice}
            onClick={handleContinue}
            sx={{
              ...(selectedPaymentOption && {
                position: 'sticky',
                bottom: '1rem',
              }),
            }}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default PaymentHome
