import { AppError } from '../../utils/appError'
import { authAxios } from '../../utils/authAxios'
import {
  ApiFindReserveGuestAppResponse,
  ApiReserveDataGuestAppResponse,
} from './findreserve_guestapp.types'

export const apiFindReserveGuestApp = async ({
  guestCode,
  userLang,
}: {
  guestCode: string
  userLang: string
}): Promise<ApiReserveDataGuestAppResponse> => {
  const response = await authAxios.post<ApiFindReserveGuestAppResponse>(
    'https://servicesapp.availroom.com/sialdPMS_Services/web/resourcescrossorigin/webcrossorigin/findreserve_guestapp_v2',
    {
      guestCode,
      userLang,
    }
  )

  if (!response.data.ok) {
    // eslint-disable-next-line prefer-promise-reject-errors
    if (response.data.status === 'NOT_FOUND') {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        new AppError({ message: response.data.error, status: response.data.status })
      )
    }
    return Promise.reject(response)
  }
  const { reserveDataGuestAppResponseList } = response.data
  const reserveData = reserveDataGuestAppResponseList[0]
  const serverPath = reserveData.serverPath.match(/^.+?(?=\/)/)?.[0] ?? ''
  return { ...reserveData, serverPath }
}
