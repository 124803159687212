import { Box, Typography, Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ApiReserveDataGuestAppResponse } from '../../api/findreserve_guestapp/findreserve_guestapp.types'
interface Props {
  apiData: ApiReserveDataGuestAppResponse
}
const HomeHeader: React.FC<Props> = ({ apiData }) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        height: '40vh',
        minHeight: '420px',
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url("${apiData.roomTypeImageUrlList[0]}") center no-repeat`,
        backgroundSize: 'cover',
        position: 'relative',
        zIndex: '1'
      }}
    >
      <Box
        sx={{
          height: '100%',
          color: 'white',
          textAlign: 'center',
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: {
            xs: '100%',
            md: '50%'
          },
          margin: 'auto'
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '1.5rem'
          }}
        >
          {t('preview.header.title')} {apiData.identifier}
        </Typography>
        <Typography
          sx={{
            fontWeight: '200',
            fontSize: '1.125rem'
          }}
        >
          {t('preview.header.accommodation')}
        </Typography>
        <Typography
          sx={{
            fontSize: '1.375rem',
            fontWeight: '400'
          }}
        >
          {apiData.roomName}
        </Typography>
        <Divider
          sx={{
            backgroundColor: 'white',
            margin: '1rem'
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between', sm: 'space-evenly' }
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: '200',
                fontSize: '1.125rem'
              }}
            >
              {t('preview.header.checkin')}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.375rem',
                fontWeight: '400'
              }}
            >
              {apiData.iniDate}
            </Typography>
            <Typography
              sx={{
                fontWeight: '200',
                fontSize: '1.125rem'
              }}
            >
              {apiData.entryTime}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: '200',
                fontSize: '1.125rem'
              }}
            >
              {t('preview.header.checkout')}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.375rem',
                fontWeight: '400'
              }}
            >
              {apiData.endDate}
            </Typography>
            <Typography
              sx={{
                fontWeight: '200',
                fontSize: '1.125rem'
              }}
            >
              {apiData.departureTime}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default HomeHeader
