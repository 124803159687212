// import React, { createContext, useContext, useMemo, useState } from 'react'
import React, { createContext, useContext, useReducer } from 'react'
import { t } from 'i18next'
import { ApiDataErrorType, useApiDataContext } from './ApiDataContext'
import {
  ApiGetReservePendingToPaidResponse,
  apiGetReservePendingToPaid,
} from '../api/getreservependingtopaid_api/getreservependingtopaid_api'
import { logger } from '../utils/logger.util'
import axios from 'axios'
import i18n from '@/i18n'

export enum PAYMENT_ACTION_TYPES {
  SET_API_PAYMENT_DATA = 'SET_API_PAYMENT_DATA',
  SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE',
  SET_LOADING = 'SET_LOADING',
  SET_STEP = 'SET_STEP',
  SET_PAYMENT_LOADING = 'SET_PAYMENT_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_PAYMENT_IFRAME = 'SET_PAYMENT_IFRAME',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_SHOW_PARTIAL_PAYMENT = 'SET_SHOW_PARTIAL_PAYMENT',
  SET_USE_VALID_CARD_ID = 'SET_USE_VALID_CARD_ID',
}

export interface PaymentContextState {
  apiPaymentData: ApiGetReservePendingToPaidResponse | undefined
  error?: string
  paymentIframe?: string
  loading: boolean
  showPartialPayment: boolean
  useValidCardIdIfExists: boolean
}

export type PaymentContextAction =
  | {
      type: PAYMENT_ACTION_TYPES.SET_PAYMENT_LOADING
      payload: {
        loading: boolean
      }
    }
  | {
      type: PAYMENT_ACTION_TYPES.SET_ERROR
      payload: {
        message: string | undefined
      }
    }
  | {
      type: PAYMENT_ACTION_TYPES.SET_PAYMENT_IFRAME
      payload: {
        url: string
      }
    }
  | {
      type: PAYMENT_ACTION_TYPES.SET_INITIAL_STATE
      payload?: undefined
    }
  | {
      type: PAYMENT_ACTION_TYPES.SET_API_PAYMENT_DATA
      payload: ApiGetReservePendingToPaidResponse
    }
  | {
      type: PAYMENT_ACTION_TYPES.SET_LOADING
      payload: boolean
    }
  | {
      type: PAYMENT_ACTION_TYPES.SET_SHOW_PARTIAL_PAYMENT
      payload: boolean
    }
  | {
      type: PAYMENT_ACTION_TYPES.SET_USE_VALID_CARD_ID
      payload: boolean
    }

const PaymentContext = createContext<{
  state: PaymentContextState
  fetchPaymentData: () => Promise<void>
  resetPaymentContextState: () => void
  partialPaymentEnabled: boolean
  setError: (params?: { message: string | undefined }) => void
  setShowPartialPayment: (show: boolean) => void
  setUseValidCardId: (use: boolean) => void
  openPaymentIframe: (iframe: string) => void
  closePaymentIframe: () => void
  clearError: () => void
}>({} as any)

const getShowPartialPayment = () => sessionStorage.getItem('showPartialPayment') === 'true'

const initialState: PaymentContextState = {
  apiPaymentData: undefined,
  loading: true,
  showPartialPayment: getShowPartialPayment(),
  useValidCardIdIfExists: true,
}

const paymentContextReducer = (
  state: PaymentContextState,
  { payload, type }: PaymentContextAction
): PaymentContextState => {
  switch (type) {
    case PAYMENT_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case PAYMENT_ACTION_TYPES.SET_API_PAYMENT_DATA:
      return {
        ...state,
        apiPaymentData: payload,
        showPartialPayment: getShowPartialPayment(),
      }
    case PAYMENT_ACTION_TYPES.SET_INITIAL_STATE:
      return initialState
    case PAYMENT_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.message,
        paymentIframe: '',
      }
    case PAYMENT_ACTION_TYPES.SET_PAYMENT_IFRAME:
      return {
        ...state,
        paymentIframe: payload.url,
      }
    case PAYMENT_ACTION_TYPES.SET_SHOW_PARTIAL_PAYMENT:
      return {
        ...state,
        showPartialPayment: payload,
      }
    case PAYMENT_ACTION_TYPES.SET_USE_VALID_CARD_ID:
      return {
        ...state,
        useValidCardIdIfExists: payload,
      }
    default:
      return initialState
  }
}

const PaymentContextProvider: React.FC = ({ children }) => {
  const {
    dispatch: apiDataContextDispatch,
    state: { apiData },
  } = useApiDataContext()
  const { serverPath, id } = apiData ?? {}
  const [state, dispatch] = useReducer(paymentContextReducer, initialState)

  const partialPaymentEnabled = state.showPartialPayment && !state.apiPaymentData?.restPaid

  const setError = (params?: { message?: string }) => {
    dispatch({
      type: PAYMENT_ACTION_TYPES.SET_ERROR,
      payload: {
        message: params?.message ?? t('error.anErrorHasOcurred'),
      },
    })
  }

  const clearError = () => {
    dispatch({
      type: PAYMENT_ACTION_TYPES.SET_ERROR,
      payload: {
        message: undefined,
      },
    })
  }

  const fetchPaymentData = async () => {
    try {
      dispatch({
        type: PAYMENT_ACTION_TYPES.SET_LOADING,
        payload: true,
      })
      const paymentDataResponse = await apiGetReservePendingToPaid({
        serverPath: serverPath!,
        data: {
          reserveId: id!,
          userLang: i18n.language,
        },
      })
      dispatch({ type: PAYMENT_ACTION_TYPES.SET_API_PAYMENT_DATA, payload: paymentDataResponse })
    } catch (error: any) {
      const responseData = axios.isAxiosError(error) ? error.response?.data : undefined
      logger.logError({
        message: 'Error on PaymentContext.tsx fetchPaymentData function',
        error,
        responseData,
      })
      return apiDataContextDispatch({
        type: 'setError',
        payload: {
          title:
            error.status === 'NOT_FOUND'
              ? 't:error.guestCodeNotFound'
              : 't:error.anErrorHasOcurred',
          type: ApiDataErrorType.ServerError,
        },
      })
    } finally {
      dispatch({
        type: PAYMENT_ACTION_TYPES.SET_LOADING,
        payload: false,
      })
    }
  }

  const resetPaymentContextState = () => {
    dispatch({
      type: PAYMENT_ACTION_TYPES.SET_INITIAL_STATE,
    })
  }

  const setShowPartialPayment = (show: boolean) => {
    dispatch({
      type: PAYMENT_ACTION_TYPES.SET_SHOW_PARTIAL_PAYMENT,
      payload: show,
    })
    if (show) {
      return sessionStorage.setItem('showPartialPayment', 'true')
    }
    sessionStorage.removeItem('showPartialPayment')
  }

  const setUseValidCardId = (use: boolean) => {
    dispatch({ type: PAYMENT_ACTION_TYPES.SET_USE_VALID_CARD_ID, payload: use })
  }

  const openPaymentIframe = (iframe: string) => {
    dispatch({
      type: PAYMENT_ACTION_TYPES.SET_PAYMENT_IFRAME,
      payload: {
        url: iframe,
      },
    })
  }

  const closePaymentIframe = () => {
    dispatch({
      type: PAYMENT_ACTION_TYPES.SET_PAYMENT_IFRAME,
      payload: {
        url: '',
      },
    })
  }

  // const providerValue = useMemo(
  //   () => ({
  //     state,
  //     fetchPaymentData,
  //     resetPaymentContextState,
  //     partialPaymentEnabled,
  //     setError,
  //     setShowPartialPayment,
  //     setUseValidCardId,
  //     openPaymentIframe,
  //     closePaymentIframe,
  //     clearError,
  //   }),
  //   [state, apiData]
  // )

  return (
    <PaymentContext.Provider
      value={{
        state,
        fetchPaymentData,
        resetPaymentContextState,
        partialPaymentEnabled,
        setError,
        setShowPartialPayment,
        setUseValidCardId,
        openPaymentIframe,
        closePaymentIframe,
        clearError,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}
export default PaymentContextProvider
export const usePaymentContext = () => useContext(PaymentContext)
