import React from 'react'
import { StyledSelect, CheckinPages } from '@/pages/checkin/Checkin'
import { Box, FormControl, MenuItem, capitalize } from '@mui/material'
import { Languages, useLanguageContext } from '@/contexts/LanguageContext'

const LanguageSelector = ({ color = 'black' }: { color?: 'black' | 'white' }) => {
  const { changeLanguage, language } = useLanguageContext()

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '2rem',
        right: '2rem',
        zIndex: '999',
        width: '100px',
      }}
    >
      <FormControl
        sx={{
          '& .MuiOutlinedInput-root': {
            ...(color === 'black' && {
              border: '1px solid black',
              '& *': {
                color: 'black',
              },
            }),
            ...(color === 'white' && {
              border: '1px solid white',
              '& *': {
                color: 'white',
              },
            }),
          },
        }}
        fullWidth
      >
        <StyledSelect
          value={language}
          onChange={(event) => {
            changeLanguage(event.target.value as Languages)
          }}
          page={CheckinPages.Checkin}
        >
          {Object.values(Languages).map((language) => {
            return (
              <MenuItem key={language} value={language}>
                {capitalize(language)}
              </MenuItem>
            )
          })}
        </StyledSelect>
      </FormControl>
    </Box>
  )
}

export default LanguageSelector
