import { UseFormGetValues } from 'react-hook-form'
import { Inputs } from '../pages/checkin/components/registerForm/components/collectDataForm/CollectDataForm'
import { nifRegex, nieRegex, emailRegex, passportRegex } from './regex'

export const validateIdentifier = (getValues: UseFormGetValues<Inputs>) => {
  const identifierId = getValues('identifierTypeId')
  const identifier = getValues('identifier')
  const isDNI = identifierId === '6D86C358DF454704ADF6AF98A8B3135D'
  const isNIE = identifierId === '6553EE440E7542669B54B596B19B2D9F'
  const isPassport = identifierId === '233092EB24424B98928696BE268640C5'

  if (isDNI) {
    return nifRegex.test(identifier)
  }
  if (isNIE) {
    return nieRegex.test(identifier)
  }
  if (isPassport) {
    return passportRegex.test(identifier)
  }

  return true
}

export const validateEmail = (email: string) => {
  if (email.length === 0) return true

  return emailRegex.test(email)
}

export const validateOnlyNumber = (string: string | number) => {
  return /^[\d,]*$/.test(string.toString())
}

export const validateOnlyInt = (string: string | number) => {
  return /^[\d]*$/.test(string.toString())
}

export const validateSupportNumber = (supportNumber: string) => {
  return /^\w\d{8}$/.test(supportNumber) || /^\w{3}\d{6}$/.test(supportNumber)
}
