import React from 'react'
// mui
import { Card, CardProps, Stack, StackProps, Typography, TypographyProps } from '@mui/material'
// Colors
import { COLORS } from '../../../styles/constants'
import OptionCardLabel from './OptionCardLabel'

export interface SelectOptionCardProps extends CardProps {
  selected?: boolean
}

const SelectOptionCard = ({
  selected = false,
  children,
  sx,
  ...restCardProps
}: SelectOptionCardProps) => {
  return (
    <Card
      sx={{
        padding: '1rem',
        borderRadius: 3,
        border: '2px solid white',
        ...(selected && {
          border: `2px solid ${COLORS.BLUE}`,
          backgroundColor: COLORS.BLUE_LIGHT_BACKGROUND,
        }),
        ...sx,
      }}
      {...restCardProps}
    >
      {children}
    </Card>
  )
}

const OptionCardTitle = ({ children, ...props }: TypographyProps) => (
  <Typography fontSize="1.1rem" fontWeight={600} {...props}>
    {children}
  </Typography>
)

const OptionCardValue = ({ children, ...restTypographyProps }: TypographyProps) => {
  return (
    <Typography
      sx={{ marginLeft: 'auto' }}
      color={COLORS.BLUE}
      fontSize="1.5rem"
      fontWeight={600}
      {...restTypographyProps}
    >
      {children}
    </Typography>
  )
}

const OptionCardFooter = ({ children, ...props }: StackProps) => (
  <Stack direction="row" marginTop=".5rem" {...props}>
    {children}
  </Stack>
)

SelectOptionCard.Label = OptionCardLabel
SelectOptionCard.Title = OptionCardTitle
SelectOptionCard.Value = OptionCardValue
SelectOptionCard.Footer = OptionCardFooter

export default SelectOptionCard
