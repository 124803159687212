import React from 'react'
import { useApiDataContext } from '../../../../contexts/ApiDataContext'
import { useTranslation } from 'react-i18next'
import HomeHeader from '../../../../components/homeHeader/HomeHeader'
import { Alert, Button, AlertTitle, Typography, Box } from '@mui/material'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { CheckinPages } from '../../Checkin'
import ListItem from './components/listItem/ListItem'
import useQuery from '../../../../hooks/useQuery'
import { Devices, QueryParams } from '../../../../App'

interface Props {
  setPage: React.Dispatch<React.SetStateAction<CheckinPages>>
}

const CheckinForm: React.FC<Props> = ({ setPage }) => {
  const {
    state: { apiData },
  } = useApiDataContext()
  const { t } = useTranslation()

  const { queryParams } = useQuery()

  const startRegisterForm = () => {
    const device = queryParams?.[QueryParams.Device]

    if (device === Devices.IOS) {
      // NO BORRAR, NECESARIO PARA CODIGO DE FLUTTER
      return console.log('OPEN_BROWSER')
    }
    return setPage(CheckinPages.Registerform)
  }

  const restAdultsToRegister = +(apiData?.restAdultsToRegister ?? 0)
  const adultsRegistered = +(apiData?.travelRegisteredList.length ?? 0)

  return (
    <Box>
      <HomeHeader apiData={apiData!} />
      <Box sx={{ padding: '2rem', maxWidth: { md: '50%' }, margin: 'auto' }}>
        {restAdultsToRegister > 0 ? (
          <Alert severity="warning" sx={{ marginBottom: '2rem' }}>
            <AlertTitle>{t('preview.warning.title')}</AlertTitle>
            {t('preview.warning.description', {
              guest: restAdultsToRegister,
            })}
          </Alert>
        ) : null}
        {restAdultsToRegister === 0 ? (
          <Alert severity="success" sx={{ marginBottom: '2rem' }}>
            <AlertTitle>{t('preview.success.title')}</AlertTitle>
            {t('preview.success.description', {
              guest: restAdultsToRegister,
            })}
          </Alert>
        ) : null}
        <Typography>{t('preview.labels.guests')}</Typography>
        <Typography
          sx={{
            fontSize: '1.125rem',
            fontWeight: '500',
            marginBottom: '1rem',
          }}
        >
          {t('preview.labels.title', { guest: restAdultsToRegister + adultsRegistered })}
        </Typography>
        {apiData!.travelRegisteredList.map((guest) => (
          <ListItem key={guest.id} guest={guest} />
        ))}
        {Array.from(Array(restAdultsToRegister)).map((_, i) => (
          <ListItem onClick={startRegisterForm} key={i} />
        ))}
        {!(restAdultsToRegister === 0) ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
                marginTop: '1.5rem',
              }}
            >
              <ErrorOutlineIcon sx={{ fontSize: '1rem' }} />
              <Typography sx={{ fontSize: '0.8rem' }}>{t('preview.labels.tag')}</Typography>
            </Box>
            <Button
              sx={{ marginTop: '1rem', width: '100%', padding: '1rem' }}
              variant="contained"
              onClick={startRegisterForm}
            >
              {t('preview.labels.nextButton')}
            </Button>
          </>
        ) : null}
      </Box>
    </Box>
  )
}
export default CheckinForm
