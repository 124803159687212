import { Box, BoxProps } from '@mui/material'
import React from 'react'

interface Props extends BoxProps {
  children: React.ReactNode
}

const CardButton = ({ children, sx, ...restBoxProps }: Props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      padding: '1rem',
      borderRadius: '5px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#eaeaea',
      },
      '&:active': {
        outline: ' 3px solid #18a0fb',
      },
      ...sx,
    }}
    {...restBoxProps}
  >
    {children}
  </Box>
)

export default CardButton
