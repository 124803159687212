import React from 'react'
import { COLORS } from '../../styles/constants'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

interface Props extends LoadingButtonProps {}

export const FatButton = ({ children, sx, ...restButtonProps }: Props) => {
  return (
    <LoadingButton
      variant="contained"
      sx={{
        padding: '1rem',
        borderRadius: 10,
        backgroundColor: COLORS.BLUE,
        ':active': {
          backgroundColor: COLORS.BLUE,
        },
        ':hover': {
          backgroundColor: COLORS.BLUE_DARK,
        },
        ...sx,
      }}
      {...restButtonProps}
    >
      {children}
    </LoadingButton>
  )
}
