import React from 'react'
import { Box, CircularProgress } from '@mui/material'

interface Props {
  color?: string
}

const Loading: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'grid',
        placeItems: 'center'
      }}
    >
      <CircularProgress
        sx={{
          ...(props.color && { color: props.color })
        }}
      />
    </Box>
  )
}
export default Loading
