import React from 'react'
import { Box, Typography } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { useTranslation } from 'react-i18next'

interface Props {
  title?: string
  description?: string
}

const ErrorComponent: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  let title = props.title
  let description = props.description

  if (title && title.startsWith('t:')) {
    title = t(title.replace('t:', ''))
  }
  if (description && description.startsWith('t:')) {
    description = t(description.replace('t:', ''))
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
        textAlign: 'center'
      }}
    >
      <CancelOutlinedIcon sx={{ fontSize: 100, color: '#ef5350' }} />
      <Typography
        sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          maxWidth: '100%',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word'
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          maxWidth: '100%',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word'
        }}
      >
        {description}
      </Typography>
      {props.children ? props.children : null}
    </Box>
  )
}
export default ErrorComponent
