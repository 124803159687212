import axios from 'axios'
import { promiseTimeout } from '../../utils/promiseTimeout'
import { ApiPaidReserveFromAppMockPending } from './paidreservefromapp_api.mock'

export type ApiPaidReserveFromAppData =
  | {
      reserveId: string
      cardName: string
      cardNumber: string
      cardExpirationDate: string
      cardCVV: string
      price: string
      paidTypeId: string
      paymentTypeId: string
    }
  | {
      reserveId: string
      price: string
      paidTypeId: string
      paymentTypeId: string
      cardId: string
      cardCVV?: string
    }

export interface ApiPaidReserveFromAppResponse {
  ok: boolean
  valid?: boolean
  message?: string
  error?: string
  status?: string
  temporalPaidId?: string
  paidComplete?: boolean
  paidRedirectURL?: string
  paidState?: string
}

export const ApiPaidReserveFromApp = async ({
  data,
  serverPath,
}: {
  data: ApiPaidReserveFromAppData
  serverPath: string
}) => {
  const response = await axios.post<ApiPaidReserveFromAppResponse>(
    `https://${serverPath}/sialdPMS/web/resourcescrossorigin/webcrossorigin/paidreservefromapp_api`,
    data,
    {
      headers: {
        Authorization: 'Basic cGFydG5lcnVzZXI6RkR0YnJ3NDc2NG4kX2poNDVnZQ==',
      },
    }
  )
  return response.data
}

export const ApiMakeCardPayment = async ({
  onStart,
  onComplete,
}: {
  onStart: () => void
  onFinish: () => void
  onComplete: () => void
  onError: (error: unknown) => void
}) => {
  onStart()
  await promiseTimeout(2000)
  const response: ApiPaidReserveFromAppResponse = ApiPaidReserveFromAppMockPending
  if (!response.ok) {
    throw new Error()
  }
  if (response.ok && response.paidComplete) {
    onComplete()
  }
}
