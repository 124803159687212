import React, { useState } from 'react'
import { Box, Stack, Typography, IconButton, Collapse } from '@mui/material'
import { t } from 'i18next'
import AddIcon from '@mui/icons-material/Add'
import CardButton from '@/components/cardButton/CardButton'
import cardIcon from '@/assets/icons/card.svg'
import { ChevronRight } from '@mui/icons-material'

const MorePaymentMethods = ({ onIntroduceNewCard }: { onIntroduceNewCard: () => void }) => {
  const [isMorePaymentMethodsDropdownOpen, setIsMorePaymentMethodsDropdownOpen] = useState(false)

  const handleToggleMorePaymentMethodsDropdown = () => {
    setIsMorePaymentMethodsDropdownOpen((prevState) => !prevState)
  }

  const handleIntroduceNewCard = () => {
    onIntroduceNewCard()
  }

  return (
    <Box textAlign="center">
      <Stack gap="1rem">
        <Stack direction="row" justifyContent="center" alignItems="center" gap="1rem">
          <Typography variant="caption" onClick={handleToggleMorePaymentMethodsDropdown}>
            {t('payment.You_can_also_enter_another_payment_method')}
          </Typography>
          <IconButton
            sx={{
              backgroundColor: ({
                palette: {
                  background: { paper },
                },
              }) => paper,
              padding: '.15rem',
            }}
            size="small"
            onClick={handleToggleMorePaymentMethodsDropdown}
          >
            <AddIcon />
          </IconButton>
        </Stack>
        <Collapse in={isMorePaymentMethodsDropdownOpen}>
          <CardButton gap=".5rem" onClick={handleIntroduceNewCard}>
            <img src={cardIcon} height={14} />
            <Typography>{t('payment.Introduce_new_card')}</Typography>
            <ChevronRight sx={{ marginLeft: 'auto' }} />
          </CardButton>
        </Collapse>
      </Stack>
    </Box>
  )
}

export default MorePaymentMethods
