import React, { useLayoutEffect, useState } from 'react'
import { Box, BoxProps, Card, Stack, Typography } from '@mui/material'
import { useApiDataContext } from '../../../../contexts/ApiDataContext'
import { t } from 'i18next'
import { usePaymentContext } from '../../../../contexts/PaymentContext'
import { FatButton } from '../../../../components/fatButton/FatButton'
import Loading from '../../../../components/loading/Loading'
import PaymentForm from '../../sections/paymentForm/PaymentForm'
import ErrorAlert from '../../../../components/errorAlert/ErrorAlert'
import { useAppNavigate } from '../../../../hooks/useAppNavigate'
import { APP_ROUTES } from '../../../../config/routes.config'
import CardIcon from '../../../../assets/icons/card.svg'
import Span from '../../../../components/span/Span'
import ConditionsCheckbox from '../../components/ConditionsCheckbox'
import { useForm } from 'react-hook-form'
import Label from '../../../../components/label/Label'
import { COLORS } from '../../../../styles/constants'
import { CVV_MAX_LENGTH } from '../../../../config/app.constants'
import TextFieldNumber from '../../../../components/textfieldNumber/TextFieldNumber'
import MorePaymentMethods from '../../sections/MorePaymentMethods'
import useCardPayment, { CardPaymentFailedError } from '@/hooks/useCardPayment'
import { dateFormatter } from '@/utils/dateFormatter.utils'

const InfoLabel = ({
  label,
  children,
  sx,
  ...boxProps
}: { label: string; children: React.ReactNode } & BoxProps) => {
  return (
    <Box
      sx={{
        borderRadius: 3,
        backgroundColor: ({ palette }) => palette.background.paper,
        padding: '.75rem',
        ...sx,
      }}
      {...boxProps}
    >
      <Stack>
        <Typography
          sx={{
            color: 'GrayText',
            fontSize: '.75rem',
          }}
        >
          {label}
        </Typography>
        <Typography>{children}</Typography>
      </Stack>
    </Box>
  )
}

const ChannelValidation = () => {
  const {
    state: { apiData },
  } = useApiDataContext()
  const {
    roomName,
    iniDate,
    endDate,
    identifier,
    id,
    channelName,
    serverPath,
    previousPaidOnCard,
  } = apiData!

  const {
    state: { apiPaymentData },
    openPaymentIframe,
    setShowPartialPayment,
    setError,
    setUseValidCardId,
  } = usePaymentContext()

  const { makeCardPayment, paymentLoading } = useCardPayment()

  const [paymentError, setPaymentError] = useState<string>()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    acceptConditions: boolean
    CVV?: string
  }>({
    defaultValues: {
      acceptConditions: false,
      CVV: '',
    },
  })

  const { navigate } = useAppNavigate()

  useLayoutEffect(() => {
    if (previousPaidOnCard) {
      navigate({
        pathname: APP_ROUTES.INITIAL,
        replace: true,
      })
    }
  }, [])

  const {
    totalPrice,
    advancePrice,
    pendingPrice,
    validCardId,
    paidTypeAdvanceId,
    paidTypeRestId,
    paymentTypeCardId,
    cardNumber,
    cardExpirationDate,
    emptyCVV,
    paidTypeStandardId,
    advancePaid,
    rateConditions,
  } = apiPaymentData!

  const payNowPrice = advancePrice && !advancePaid ? advancePrice : pendingPrice
  const payNowTypeId = advancePrice && !advancePaid ? paidTypeAdvanceId : paidTypeRestId

  const handleIntroduceNewCard = () => {
    setUseValidCardId(false)
    navigate({
      pathname: APP_ROUTES.PAYMENT,
    })
  }

  const onSubmit = handleSubmit(async (data) => {
    if (!validCardId) throw new Error('invalid card id')
    makeCardPayment({
      data: {
        cardId: validCardId!,
        paidTypeId: payNowTypeId!,
        paymentTypeId: paymentTypeCardId,
        reserveId: id,
        price: payNowPrice,
        cardCVV: data.CVV,
      },
      onAuthorizeIframe: (iframe) => {
        openPaymentIframe(iframe)
      },
      onError: (error) => {
        console.log(error)
        if (error instanceof CardPaymentFailedError) {
          setShowPartialPayment(true)
        }
        setPaymentError(error.message)
      },
      onSuccess: () => {
        navigate({ pathname: APP_ROUTES.INITIAL })
      },
      serverPath,
    })
  })

  if (paymentLoading) {
    return <Loading />
  }

  // eslint-disable-next-line no-constant-condition
  if (paymentError) {
    return (
      <>
        <ErrorAlert sx={{ marginBottom: '1rem' }} description={paymentError} />
        <PaymentForm
          paidTypeId={paidTypeStandardId}
          onPaymentError={(error) => {
            setError({
              message: error.message,
            })
            navigate({
              pathname: APP_ROUTES.PAYMENT,
            })
          }}
          onPaymentSuccess={() => {
            navigate({ pathname: APP_ROUTES.INITIAL })
          }}
        />
      </>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack gap="1rem">
        <InfoLabel label={t('labels.Stablishment')}>{roomName}</InfoLabel>

        <InfoLabel label={t('labels.Number_of_reserve')}>{identifier}</InfoLabel>

        <Stack direction="row" gap="1rem">
          <InfoLabel flex={1} label={t('labels.Checkin')}>
            {iniDate}
          </InfoLabel>

          <InfoLabel flex={1} label={t('labels.Checkout')}>
            {endDate}
          </InfoLabel>
        </Stack>

        <InfoLabel flex={1} label={t('payment.channelValidation.reserve_Total')}>
          {`${totalPrice}€`}
        </InfoLabel>

        <InfoLabel
          label={t('payment.channelValidation.Total_pay_now')}
        >{`${payNowPrice}€`}</InfoLabel>

        <Stack gap="1rem">
          <Typography textAlign="center" fontWeight={500}>
            {t('payment.channelValidation.Confirm_payment_with_the_card_you_entered_in_channel', {
              channel: channelName,
            })}
          </Typography>

          <Card
            sx={{
              padding: '1rem',
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap="1rem">
                <img src={CardIcon} width="16px" />
                <Typography>
                  <Span sx={{ position: 'relative', top: '.15rem' }}>**** **** ****</Span>
                  {` ${cardNumber}`}
                </Typography>
              </Stack>

              <Box paddingRight="1rem">
                <Typography>
                  {cardExpirationDate && dateFormatter.formatToCardExpMonthYear(cardExpirationDate)}
                </Typography>
              </Box>
            </Stack>
          </Card>

          {emptyCVV && (
            <Stack direction="row" alignItems="center" gap="1rem" justifyContent="space-between">
              <Label sx={{ backgroundColor: COLORS.WARNING_BACKGROUND }} flex={1.5}>
                <Span>{t('payment.CVV_invalid_Introduce_the_CVV')}</Span>
              </Label>
              <TextFieldNumber
                label={t('labels.CVV')}
                sx={{
                  flex: 0.5,
                }}
                onlyInt
                inputProps={{
                  maxLength: CVV_MAX_LENGTH,
                }}
                {...register('CVV', {
                  required: `${t('validations.Required')}`,
                  maxLength: {
                    value: 4,
                    message: `${t('validations.Invalid')}`,
                  },
                  shouldUnregister: true,
                })}
                error={!!errors.CVV}
                helperText={errors.CVV?.message}
              />
            </Stack>
          )}

          <MorePaymentMethods onIntroduceNewCard={handleIntroduceNewCard} />

          <Box mt="1rem">
            <ConditionsCheckbox
              error={errors.acceptConditions?.message}
              checkboxProps={{
                ...register('acceptConditions', {
                  validate: (value) => (value ? true : `${t('validations.Required')}`),
                }),
              }}
              conditions={rateConditions}
            />
          </Box>
        </Stack>

        <FatButton type="submit">
          <Span>
            {t('payment.channelValidation.Authorize_the_payment_of_amount', {
              amount: `${payNowPrice}€`,
            })}
          </Span>
        </FatButton>
      </Stack>
    </form>
  )
}

export default ChannelValidation
