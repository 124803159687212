import { Typography, TypographyProps } from '@mui/material'
import React from 'react'

interface Props extends TypographyProps {}

const Span = (props: Props) => {
  return (
    <Typography component="span" {...props}>
      {props.children}
    </Typography>
  )
}

export default Span
