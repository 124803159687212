import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { ApiDataContextProvider } from './contexts/ApiDataContext'
import Loading from './components/loading/Loading'
import { Box, ThemeProvider } from '@mui/material'
import './i18n'
import { RegisterFormProvider } from './contexts/RegisterFormContext'
import 'normalize.css'
import App from './App'
import { muiTheme } from './styles/mui/muiTheme'
import PaymentContextProvider from './contexts/PaymentContext'
// SENTRY
import * as Sentry from '@sentry/react'
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import LanguageContextProvider from './contexts/LanguageContext'
import { BrowserRouter } from 'react-router-dom'

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction) {
  const urlParams = new URLSearchParams(window.location.search)
  const guestCode = urlParams.get('guestcode')
  Sentry.init({
    dsn: 'https://7ca4013c82c64128a3e7e38e229ffdd1@o1290103.ingest.sentry.io/4505210423410688',
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing(),
      new ExtraErrorDataIntegration({
        depth: 50,
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: true,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 50,
    beforeSend: (event, hint) => {
      const error: any = hint.originalException

      if (
        error?.error?.message &&
        (error.error.message === 'Request aborted' || error.error.message === 'Network Error')
      )
        return null

      if (guestCode) event.fingerprint = [guestCode]
      return event
    },
    denyUrls: [
      'https://checkin.availroom.com/mobbscan-api',
      /checkin.availroom.com\/mobbscan-api\//i,
    ],
  })

  Sentry.setTag('guestCode', guestCode)
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense
        fallback={
          <Box sx={{ width: '100vw', height: '100vh' }}>
            <Loading />
          </Box>
        }
      >
        <ThemeProvider theme={muiTheme}>
          <LanguageContextProvider>
            <ApiDataContextProvider>
              <PaymentContextProvider>
                <RegisterFormProvider>
                  <App />
                </RegisterFormProvider>
              </PaymentContextProvider>
            </ApiDataContextProvider>
          </LanguageContextProvider>
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
