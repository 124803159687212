export const APP_ROUTES = {
  INITIAL: '/',
  CHECKIN: '/checkin',
  PAYMENT: '/payment',
  PAYMENT_CHANNEL_VALIDATION: '/payment/channel-validation',
  PAYMENT_ADVANCE: '/payment/advance',
  PAYMENT_BAIL: '/payment/deposit',
  PAYMENT_TOTAL: '/payment/total',
  PAYMENT_PARTIAL: '/payment/partial',
}
