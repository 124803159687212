import { Box, BoxProps } from '@mui/material'
import React, { ReactNode } from 'react'
import { COLORS } from '../../styles/constants'

const Label = ({ children, sx, ...restBoxProps }: { children: ReactNode } & BoxProps) => (
  <Box
    sx={{
      backgroundColor: COLORS.LIGHT_GREEN,
      borderRadius: 2,
      color: 'white',
      padding: '.5rem .5rem',
      textAlign: 'center',
      fontSize: '.75rem',
      ...sx,
    }}
    {...restBoxProps}
  >
    {children}
  </Box>
)

export default Label
