import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
} from '@mui/material'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import parse from 'html-react-parser'
import CustomModal from '@/components/customModal/CustomModal'

const ConditionsCheckbox = ({
  error,
  checkboxProps,
  conditions,
}: {
  error?: string
  checkboxProps: CheckboxProps
  conditions?: string
}) => {
  const [openConditions, setOpenConditions] = useState(false)
  return (
    <FormControl error={!!error}>
      <FormControlLabel
        control={<Checkbox {...checkboxProps} />}
        label={
          <Trans i18nKey="payment.Ive_read_booking_conditions">
            Ive read booking{' '}
            <Link
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setOpenConditions(true)
              }}
            >
              conditions
            </Link>
          </Trans>
        }
      />
      {error && <FormHelperText>{error}</FormHelperText>}
      <CustomModal open={openConditions} onClose={() => setOpenConditions(false)} showCloseBtn>
        <Box>{conditions && parse(conditions)}</Box>
      </CustomModal>
    </FormControl>
  )
}

export default ConditionsCheckbox
