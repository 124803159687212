import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <Box sx={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }}>
      <Box textAlign="center">
        <ErrorIcon sx={{ fontSize: '5rem', color: 'red' }} />
        <Typography
          sx={{
            fontSize: '1.5rem',
          }}
        >
          Página no encontrada
        </Typography>
        <Link to={'/'}>
          <Button>Volver</Button>
        </Link>
      </Box>
    </Box>
  )
}

export default NotFound
