import { QueryParams } from '@/App'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const useQuery = () => {
  const [queryParams, setQueryParams] = useState<{ [key: string]: string | undefined }>()
  const [urlSearchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    console.log(urlSearchParams)
    const queryParamsObject: { [key: string]: string | undefined } = {}
    Object.values(QueryParams).forEach((param) => {
      queryParamsObject[param] = urlSearchParams.get(param) ?? undefined
    })
    console.log(queryParamsObject)
    setQueryParams(queryParamsObject)
  }, [urlSearchParams])

  const setURLQueryParams = (params: { [key in QueryParams]?: string | undefined }) => {
    const newUrlSearchParams = new URLSearchParams(urlSearchParams)
    Object.entries(params).forEach(([key, value]) => {
      newUrlSearchParams.set(key, value)
    })
    setSearchParams(newUrlSearchParams)
  }

  return { queryParams, setURLQueryParams }
}

export default useQuery
