import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { QueryParams } from '../App'
import { Document } from '../libs/mobbscan/mobbscan.types'
import { Guest } from '../api/savetravelregister_guestapp/savetravelregister_guestapp.types'
import useQuery from '../hooks/useQuery'

export enum RegisterFormStepNames {
  collectDataForm = 'collectDataForm',
  signContract = 'signContract',
  checkinComplete = 'checkinComplete',
}
interface RegisterFormStateI {
  step: RegisterFormStepNames
  guest: Guest
  photosDoc: {
    photoFront?: string
    photoBack?: string
  }
  photoFace?: string
  photoSignature?: string
  rawData?: Document
}

export enum RegisterFormTypes {
  ChangeStep = 'ChangeStep',
  UpdateGuest = 'UpdateGuest',
  SetphotoFront = 'SetphotoFront',
  SetPhotoBack = 'SetPhotoBack',
  SetPhotoSignature = 'SetPhotoSignature',
  SetRawData = 'SetRawData',
  SetPhotoFace = 'SetPhotoFace',
}

type RegisterFormAction =
  | {
      type: RegisterFormTypes.ChangeStep
      payload: RegisterFormStepNames
    }
  | {
      type: RegisterFormTypes.UpdateGuest
      payload: Partial<Guest>
    }
  | {
      type: RegisterFormTypes.SetphotoFront
      payload: string
    }
  | {
      type: RegisterFormTypes.SetPhotoBack
      payload: string
    }
  | {
      type: RegisterFormTypes.SetPhotoSignature
      payload: string
    }
  | {
      type: RegisterFormTypes.SetRawData
      payload: Document
    }
  | {
      type: RegisterFormTypes.SetPhotoFace
      payload: string
    }
interface RegisterFormContextI {
  state: RegisterFormStateI
  moveForwardsSteps: () => void
  moveBackwardsSteps: () => void
  updateGuest: (guest: Partial<Guest>) => void
  resetGuest: () => void
  resetSteps: () => void
  dispatch: React.Dispatch<RegisterFormAction>
}

const initialState: RegisterFormStateI = {
  step: RegisterFormStepNames.collectDataForm,
  guest: {
    reserveId: '',
    name: '',
    surname1: '',
    surname2: '',
    identifier: '',
    identifierTypeId: '',
    countryId: '',
    bornDate: '',
    email: '',
    sexId: '',
    userLang: '',
    address: '',
    postalCode: '',
  },
  photosDoc: {
    photoBack: undefined,
    photoFront: undefined,
  },
  photoFace: undefined,
  photoSignature: undefined,
}

const RegisterFormContext = createContext({} as RegisterFormContextI)

const registerFormReducer = (state: RegisterFormStateI, action: RegisterFormAction) => {
  switch (action.type) {
    case RegisterFormTypes.ChangeStep: {
      return { ...state, step: action.payload }
    }
    case RegisterFormTypes.UpdateGuest: {
      const newState = {
        ...state,
        guest: { ...state.guest, ...action.payload },
      }
      return newState
    }
    case RegisterFormTypes.SetphotoFront: {
      const newState: RegisterFormStateI = {
        ...state,
        photosDoc: { ...state.photosDoc, photoFront: action.payload },
      }
      return newState
    }

    case RegisterFormTypes.SetPhotoBack: {
      const newState: RegisterFormStateI = {
        ...state,
        photosDoc: { ...state.photosDoc, photoBack: action.payload },
      }
      return newState
    }

    case RegisterFormTypes.SetPhotoFace: {
      const newState: RegisterFormStateI = {
        ...state,
        photoFace: action.payload,
      }
      return newState
    }

    case RegisterFormTypes.SetPhotoSignature: {
      const newState: RegisterFormStateI = {
        ...state,
        photoSignature: action.payload,
      }
      return newState
    }

    case RegisterFormTypes.SetRawData: {
      const newState: RegisterFormStateI = {
        ...state,
        rawData: action.payload,
      }
      return newState
    }

    default: {
      throw new Error(`Unhandled action type: ${(action as any).type}`)
    }
  }
}

export const RegisterFormProvider: React.FC = (props) => {
  const { queryParams } = useQuery()
  const [state, dispatch] = useReducer(registerFormReducer, {
    ...initialState,
    guest: {
      ...initialState.guest,
      userLang: queryParams?.[QueryParams.LangId] || 'es',
    },
  })

  const resetSteps = useCallback(() => {
    dispatch({
      type: RegisterFormTypes.ChangeStep,
      payload: RegisterFormStepNames.collectDataForm,
    })
  }, [])

  const moveForwardsSteps = useCallback(() => {
    const stepName = state.step
    const nextStep =
      stepName === RegisterFormStepNames.collectDataForm
        ? RegisterFormStepNames.signContract
        : stepName === RegisterFormStepNames.signContract
        ? RegisterFormStepNames.checkinComplete
        : RegisterFormStepNames.collectDataForm

    dispatch({ type: RegisterFormTypes.ChangeStep, payload: nextStep })
  }, [state.step])

  const moveBackwardsSteps = useCallback(() => {
    const stepName = state.step
    const previousStep =
      stepName === RegisterFormStepNames.checkinComplete
        ? RegisterFormStepNames.signContract
        : stepName === RegisterFormStepNames.signContract
        ? RegisterFormStepNames.collectDataForm
        : RegisterFormStepNames.checkinComplete

    dispatch({ type: RegisterFormTypes.ChangeStep, payload: previousStep })
  }, [state.step])

  const updateGuest = useCallback(
    (guest: Partial<Guest>) => {
      dispatch({
        type: RegisterFormTypes.UpdateGuest,
        payload: { ...state.guest, ...guest },
      })
    },
    [state.guest]
  )

  const resetGuest = useCallback(() => {
    dispatch({
      type: RegisterFormTypes.UpdateGuest,
      payload: {
        ...initialState.guest,
        reserveId: state.guest.reserveId,
        userLang: state.guest.userLang,
      },
    })
  }, [state.guest])

  return (
    <RegisterFormContext.Provider
      value={{
        state,
        moveForwardsSteps,
        moveBackwardsSteps,
        updateGuest,
        resetGuest,
        resetSteps,
        dispatch,
      }}
    >
      {props.children}
    </RegisterFormContext.Provider>
  )
}

export const useRegisterFormContext = () => {
  const context = useContext(RegisterFormContext)
  if (context === undefined) {
    throw new Error('This hook can only be called inside the RegisterFormProvider')
  }
  return context
}
