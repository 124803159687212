import { useState } from 'react'
import {
  ApiPaidReserveFromApp,
  ApiPaidReserveFromAppData,
  ApiPaidReserveFromAppResponse,
} from '@/api/paidreservefromapp_api/paidreservefromapp_api'
import axios from 'axios'
import { logger } from '@/utils/logger.util'
import { ApiCheckPaymentStateFromApp } from '@/api/checkpaymentstatefromapp_api/checkpaymentstatefromapp_api'
import { t } from 'i18next'
import { toast } from 'react-toastify'
import { apiGetReservePendingToPaid } from '@/api/getreservependingtopaid_api/getreservependingtopaid_api'
import i18n from '@/i18n'

export class CardPaymentBaseError extends Error {
  message = `${t('payment.error.Payment_failed.title')}`

  constructor({ message }: { message?: string }) {
    super(message)
    if (message) {
      this.message = message
    }
  }
}

export class CardPaymentFailedError extends CardPaymentBaseError {
  message = `${t('payment.error.Payment_failed.description')}`

  constructor({ message }: { message?: string }) {
    super({ message })
    if (message) {
      this.message = message
    }
  }
}

export class CardPaymentAlreadyPaidError extends CardPaymentBaseError {
  constructor() {
    super({
      message: `${t('payment.error.Payment_already_paid.title')}`,
    })
  }
}

export type CardPaymentError = CardPaymentFailedError | CardPaymentAlreadyPaidError

const useCardPayment = () => {
  const [paymentLoading, setPaymentLoading] = useState(false)

  const handlePaymentComplete = (onSuccess?: () => void) => {
    toast.success(t('payment.Payment_successful'))
    onSuccess?.()
  }

  const makeCardPayment = async ({
    data,
    onError,
    onSuccess,
    serverPath,
    onAuthorizeIframe,
  }: {
    data: ApiPaidReserveFromAppData
    onSuccess?: () => void
    onError?: (error: CardPaymentError) => void
    serverPath: string
    onAuthorizeIframe: (iframe: string) => void
  }) => {
    try {
      setPaymentLoading(true)
      const pendingPaymentRes = await apiGetReservePendingToPaid({
        serverPath,
        data: {
          reserveId: data.reserveId,
          userLang: i18n.language,
        },
      })

      const isRestAndAlreadyPaid =
        data.paidTypeId === pendingPaymentRes.paidTypeRestId && pendingPaymentRes.restPaid

      const isBailAndAlreadyPaid =
        data.paidTypeId === pendingPaymentRes.paidTypeBailId && pendingPaymentRes.bailPaid

      const isAdvanceAndAlreadyPaid =
        data.paidTypeId === pendingPaymentRes.paidTypeAdvanceId && pendingPaymentRes.advancePaid

      if (isRestAndAlreadyPaid || isBailAndAlreadyPaid || isAdvanceAndAlreadyPaid) {
        throw new CardPaymentAlreadyPaidError()
      }

      const response: ApiPaidReserveFromAppResponse = await ApiPaidReserveFromApp({
        data,
        serverPath: serverPath!,
      })
      if (!response.ok) {
        throw response
      }
      if (response.ok && response.paidComplete) {
        handlePaymentComplete(onSuccess)
        return
      }
      if (response.paidRedirectURL && response.temporalPaidId) {
        onAuthorizeIframe(response.paidRedirectURL)
        startCheckingCardPayment({
          temporalPaidId: response.temporalPaidId,
          onError,
          onSuccess,
          reserveId: data.reserveId,
          serverPath,
        })
        return
      }
      throw response
    } catch (error) {
      let errorMessage = t('error.tryAgainOrContactUs')
      let responseData
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response
        responseData = errorResponse?.data
        if (errorResponse?.status !== 500) {
          errorMessage = t('payment.error.Payment_failed.description')
        }
      }
      logger.logError({
        message: 'Error on useCardPayment.tsx makeCardPayment function',
        error,
        responseData,
      })
      if (error instanceof CardPaymentBaseError) {
        return onError?.(error)
      }
      onError?.(
        new CardPaymentFailedError({
          message: errorMessage,
        })
      )
    } finally {
      setPaymentLoading(false)
    }
  }

  const startCheckingCardPayment = async (props: {
    temporalPaidId: string
    onError?: (e: CardPaymentError) => void
    onSuccess?: () => void
    reserveId: string
    serverPath: string
  }) => {
    const { temporalPaidId, onError, onSuccess, reserveId, serverPath } = props
    try {
      const response = await ApiCheckPaymentStateFromApp({
        serverPath: serverPath!,
        data: {
          reserveId: reserveId!,
          temporalPaidId: temporalPaidId!,
        },
      })
      if (!response.ok) {
        throw response
      }
      if (response.paidState === 'completado') {
        handlePaymentComplete(onSuccess)
        return
      }
      if (response.paidState === 'pendiente') {
        setTimeout(() => {
          startCheckingCardPayment(props)
        }, 1000)
        return
      }
      throw response
    } catch (error) {
      const responseData = axios.isAxiosError(error) ? error.response?.data : undefined
      logger.logError({
        message: 'Error on PaymentContext.tsx startCheckingCardPayment function',
        error,
        responseData,
      })
      if (axios.isAxiosError(error) && error.message === 'Network Error') {
        setTimeout(() => {
          startCheckingCardPayment(props)
        }, 1000)
        return
      }
      if (error instanceof CardPaymentBaseError) {
        return onError?.(error)
      }
      onError?.(
        new CardPaymentFailedError({
          message: responseData?.error,
        })
      )
    }
  }

  return {
    paymentLoading,
    makeCardPayment,
  }
}

export default useCardPayment
