import React, { useLayoutEffect } from 'react'
import PaymentForm from '../../sections/paymentForm/PaymentForm'
import { usePaymentContext } from '@/contexts/PaymentContext'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { APP_ROUTES } from '@/config/routes.config'

const PaymentTotal = () => {
  const {
    state: { apiPaymentData },
    setError,
  } = usePaymentContext()
  const { pendingPrice, paidTypeRestId, restPaid } = apiPaymentData ?? {}

  const { navigate } = useAppNavigate()

  useLayoutEffect(() => {
    if (restPaid) {
      navigate({
        pathname: APP_ROUTES.PAYMENT,
        replace: true,
      })
    }
  }, [])

  return (
    <PaymentForm
      priceToPay={pendingPrice}
      paidTypeId={paidTypeRestId!}
      onPaymentError={(e) => {
        setError({
          message: e.message,
        })
        navigate({
          pathname: APP_ROUTES.PAYMENT,
          replace: true,
        })
      }}
      onPaymentSuccess={() => {
        navigate({
          pathname: APP_ROUTES.PAYMENT,
          replace: true,
        })
      }}
    />
  )
}

export default PaymentTotal
