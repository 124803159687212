import React from 'react'
import SelectOptionCard, { SelectOptionCardProps } from '../../../components/SelectOptionCard'
import { COLORS } from '../../../../../styles/constants'
import { t } from 'i18next'
import { Typography } from '@mui/material'

import PaymentsIcon from '@mui/icons-material/Payments'

interface Props extends SelectOptionCardProps {
  bailPaid: boolean
  bailToRefundPrice?: string
  bailPrice?: string
  bailRefund: boolean
  bailRefundDate?: string
}

const DepositOptionCard = ({
  bailPrice,
  bailPaid,
  bailToRefundPrice,
  bailRefund,
  bailRefundDate,
  ...selectOptionCardProps
}: Props) => {
  const depositPendingToRefund = bailPaid && !bailRefund

  if (bailRefund)
    return (
      <SelectOptionCard {...selectOptionCardProps}>
        <SelectOptionCard.Title>{t('payment.Deposit')}</SelectOptionCard.Title>
        <Typography>{t('payment.Deposit-description')}</Typography>
        <SelectOptionCard.Footer>
          {bailRefundDate && (
            <SelectOptionCard.Label color="success">
              <PaymentsIcon />
              {`${t('payment.Deposit_returned_on')}: ${bailRefundDate}`}
            </SelectOptionCard.Label>
          )}
        </SelectOptionCard.Footer>
      </SelectOptionCard>
    )

  if (depositPendingToRefund)
    return (
      <SelectOptionCard {...selectOptionCardProps}>
        <SelectOptionCard.Title>{t('payment.Deposit')}</SelectOptionCard.Title>
        <Typography>{t('payment.Deposit-description')}</Typography>
        <SelectOptionCard.Footer>
          <SelectOptionCard.Label color="warning">
            <PaymentsIcon />
            {`${t('payment.Paid')}:${t('payment.Refund_pending')}`}
          </SelectOptionCard.Label>
          <SelectOptionCard.Value
            color={COLORS.WARNING_TEXT}
          >{`${bailToRefundPrice}€`}</SelectOptionCard.Value>
        </SelectOptionCard.Footer>
      </SelectOptionCard>
    )

  return (
    <SelectOptionCard {...selectOptionCardProps}>
      <SelectOptionCard.Title>{t('payment.Deposit')}</SelectOptionCard.Title>
      <Typography>{t('payment.Deposit-description')}</Typography>
      <SelectOptionCard.Footer>
        <SelectOptionCard.Label>
          <PaymentsIcon />
          {t('payment.Required_before_checkin')}
        </SelectOptionCard.Label>
        <SelectOptionCard.Value>{`${bailPrice}€`}</SelectOptionCard.Value>
      </SelectOptionCard.Footer>
    </SelectOptionCard>
  )
}

export default DepositOptionCard
