import React from 'react'
import { Box } from '@mui/material'
import Loading from '../../components/loading/Loading'

const HoverLoadingBlur: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        zIndex: 1
      }}
    >
      <Loading />
    </Box>
  )
}
export default HoverLoadingBlur
