import React from 'react'
import { Box, BoxProps, Stack } from '@mui/material'
import { COLORS } from '../../../styles/constants'

interface Props extends BoxProps {
  color?: 'success' | 'warning'
}

const OptionCardLabel = ({ children, sx, color, ...restBoxProps }: Props) => {
  let backgroundColor = COLORS.BLUE_CARD_BACKGROUND
  let textColor = COLORS.BLUE
  if (color === 'success') {
    backgroundColor = COLORS.SUCCESS
    textColor = COLORS.SUCCESS_TEXT
  }
  if (color === 'warning') {
    backgroundColor = COLORS.WARNING
    textColor = COLORS.WARNING_TEXT
  }
  return (
    <Box
      sx={{
        backgroundColor,
        paddingX: '.5rem',
        borderRadius: 2,
        display: 'inline-flex',
        marginTop: '.15rem',
        color: textColor,
        fontSize: '0.75rem',
        ...sx,
      }}
      {...restBoxProps}
    >
      <Stack direction="row" alignItems="center" gap=".25rem">
        {children}
      </Stack>
    </Box>
  )
}

export default OptionCardLabel
