import {
  useRegisterFormContext,
  RegisterFormStepNames,
} from '../../../../contexts/RegisterFormContext'
import { Box, Button } from '@mui/material'
import React, { lazy, Suspense } from 'react'
import Stepper from './components/stepper/Stepper'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import Loading from '../../../../components/loading/Loading'
import { CheckinPages } from '../../Checkin'

const CollectDataForm = lazy(() => import('./components/collectDataForm/CollectDataForm'))
const SignContractForm = lazy(() => import('./components/signContractForm/SignContractForm'))
const CheckinCompleteForm = lazy(
  () => import('./components/checkinCompleteForm/CheckinCompleteForm')
)

interface Props {
  setPage: React.Dispatch<React.SetStateAction<CheckinPages>>
}

const RegisterForm: React.FC<Props> = ({ setPage }) => {
  const {
    state: { step },
    moveBackwardsSteps,
    resetGuest,
  } = useRegisterFormContext()

  const returnBackwards = () => {
    if (step === RegisterFormStepNames.collectDataForm) {
      resetGuest()
      return setPage(CheckinPages.Checkin)
    }
    moveBackwardsSteps()
  }

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        padding: '2rem',
        width: {
          sx: '100%',
          md: '50%',
        },
        margin: 'auto',
        ...(step === RegisterFormStepNames.checkinComplete && {
          paddingTop: '3rem',
        }),
      }}
    >
      {step !== RegisterFormStepNames.checkinComplete && (
        <Button
          onClick={returnBackwards}
          sx={{ alignSelf: 'flex-start' }}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
        >
          {t('stepper.labels.goBack')}
        </Button>
      )}
      <Stepper step={step} />
      <Suspense
        fallback={
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <Loading />
          </Box>
        }
      >
        {step === RegisterFormStepNames.collectDataForm ? (
          <CollectDataForm />
        ) : step === RegisterFormStepNames.signContract ? (
          <SignContractForm />
        ) : step === RegisterFormStepNames.checkinComplete ? (
          <CheckinCompleteForm setPage={setPage} />
        ) : null}
      </Suspense>
    </Box>
  )
}
export default RegisterForm
