import React from 'react'
import { RegisterFormStepNames } from '../../../../../../contexts/RegisterFormContext'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'
interface Props {
  step: RegisterFormStepNames
}

const Stepper: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '2rem 0',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            backgroundColor: `${
              step === RegisterFormStepNames.collectDataForm ? '#0e82c5' : '#c4c4c4'
            }`,
            color: 'white',
            borderRadius: '100rem',
            width: '2rem',
            height: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {step === RegisterFormStepNames.signContract ||
          step === RegisterFormStepNames.checkinComplete ? (
            <CheckIcon />
          ) : (
            1
          )}
        </Box>
        <Typography sx={{ fontSize: '0.9rem' }}>{t('stepper.labels.1')}</Typography>
      </Box>
      <Box
        sx={{
          width: '25%',
          height: '1px',
          backgroundColor: '#c4c4c4',
        }}
      ></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            backgroundColor: ` ${
              step === RegisterFormStepNames.signContract ? '#0e82c5' : '#c4c4c4'
            }`,
            color: 'white',
            borderRadius: '100rem',
            width: '2rem',
            height: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {step === RegisterFormStepNames.checkinComplete ? <CheckIcon /> : 2}
        </Box>
        <Typography sx={{ fontSize: '0.9rem' }}>{t('stepper.labels.2')}</Typography>
      </Box>
      <Box
        sx={{
          width: '25%',
          height: '1px',
          backgroundColor: '#c4c4c4',
        }}
      ></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            backgroundColor: ` ${
              step === RegisterFormStepNames.checkinComplete ? '#0e82c5' : '#c4c4c4'
            }`,
            color: 'white',
            borderRadius: '100rem',
            width: '2rem',
            height: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          3
        </Box>
        <Typography sx={{ fontSize: '0.9rem' }}>{t('stepper.labels.3')}</Typography>
      </Box>
    </Box>
  )
}
export default Stepper
