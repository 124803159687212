import axios from 'axios'

export interface ApiCheckPaymentStateFromAppData {
  reserveId: string
  temporalPaidId: string
}
export interface ApiCheckPaymentStateFromAppResponse {
  ok: boolean
  paidState: 'pendiente' | 'completado' | 'cancelado'
}

export const ApiCheckPaymentStateFromApp = async ({
  data,
  serverPath,
}: {
  data: ApiCheckPaymentStateFromAppData
  serverPath: string
}) => {
  const response = await axios.post<ApiCheckPaymentStateFromAppResponse>(
    `https://${serverPath}/sialdPMS/web/resourcescrossorigin/webcrossorigin/checkpaymentstatefromapp_api`,
    data,
    {
      headers: {
        Authorization: 'Basic cGFydG5lcnVzZXI6RkR0YnJ3NDc2NG4kX2poNDVnZQ==',
      },
    }
  )
  return response.data
}
