import { dateFormatter } from '@/utils/dateFormatter.utils'
import axios from 'axios'

export interface ApiGetReservePendingToPaidResponse {
  ok: boolean
  valid: boolean
  message: string
  error: string
  status: string
  totalPrice: string
  pendingPrice: string
  freeCancelLimitDate?: string
  freeCancel: boolean
  bailPaid: boolean
  bailToRefundPrice?: string
  bailRefund: boolean
  bailRefundDate?: string
  bailPrice?: string
  bailPaymentDate?: string
  paidTypeBailName?: string
  advancePaid: boolean
  advancePrice?: string
  advancePaymentDate?: string
  paidTypeAdvanceName?: string
  restPaid: boolean
  restPaymentDate?: string
  restPrice?: string
  paidTypeRestName?: string
  penalizePrice?: string
  paidTypeRestId?: string
  paidTypeBailId?: string
  paidTypeAdvanceId?: string
  paidTypeStandardId: string
  paymentTypeCardId: string
  paymentTypeBizumId: string
  messageRequest: string
  rateConditions?: string
  validCardId?: string
  cardNumber?: string
  cardExpirationDate?: Date
  cardName?: string
  reserveChannelIdentifier?: string
  emptyCVV?: boolean
  advancePercent: string
}

interface ApiGetReservePendingToPaidFetchResponse {
  ok: boolean
  valid: boolean
  message: string
  error: string
  status: string
  totalPrice: string
  pendingPrice: string
  freeCancelLimitDate?: string
  freeCancel: boolean
  bailPaid: boolean
  bailToRefundPrice?: string
  bailRefund: boolean
  bailRefundDate?: string
  bailPrice?: string
  bailPaymentDate?: string
  paidTypeBailName?: string
  advancePaid: boolean
  advancePrice?: string
  advancePaymentDate?: string
  paidTypeAdvanceName?: string
  restPaid: boolean
  restPaymentDate?: string
  restPrice?: string
  paidTypeRestName?: string
  penalizePrice?: string
  paidTypeRestId?: string
  paidTypeBailId?: string
  paidTypeAdvanceId?: string
  paidTypeStandardId: string
  paymentTypeCardId: string
  paymentTypeBizumId: string
  messageRequest: string
  rateConditions?: string
  validCardId?: string
  cardNumber?: string
  cardExpirationDate?: string
  cardName?: string
  reserveChannelIdentifier?: string
  emptyCVV?: boolean
  advancePercent: string
}

export const apiGetReservePendingToPaid = async ({
  serverPath,
  data,
}: {
  serverPath: string
  data: {
    reserveId: string
    userLang?: string
  }
}): Promise<ApiGetReservePendingToPaidResponse> => {
  const response = await axios.post<ApiGetReservePendingToPaidFetchResponse>(
    `https://${serverPath}/sialdPMS/web/resourcescrossorigin/webcrossorigin/getreservependingtopaid_api`,
    data,
    {
      headers: {
        Authorization: 'Basic cGFydG5lcnVzZXI6RkR0YnJ3NDc2NG4kX2poNDVnZQ==',
      },
    }
  )

  const resData = response.data

  const trimmedExpirationDate = resData.cardExpirationDate?.replaceAll(' ', '').replaceAll('-', '/')
  const parsedExpirationDate = trimmedExpirationDate
    ? dateFormatter.parseToDate(
        trimmedExpirationDate,
        trimmedExpirationDate.length > 5 ? 'MM/yyyy' : 'MM/yy'
      )
    : undefined

  return { ...response.data, cardExpirationDate: parsedExpirationDate }
}
