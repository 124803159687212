import React from 'react'
import { Box, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { TravelerRegistered } from '../../../../../../api/findreserve_guestapp/findreserve_guestapp.types'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface Props {
  guest?: TravelerRegistered
  onClick?: () => void
}

const ListItem: React.FC<Props> = ({ guest, onClick }) => {
  const { t } = useTranslation()

  return (
    <Box
      onClick={guest ? undefined : onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1.5rem 1.8rem',
        backgroundColor: 'white',
        borderRadius: '10px',
        marginTop: '0.5rem',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#eaeaea'
        },
        '&:active': {
          outline: ' 3px solid #18a0fb'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem'
        }}
      >
        {guest
          ? (
            <CheckCircleIcon css={{ fontSize: '48px', color: '#0e82c5' }} />
          )
          : (
            <ErrorIcon css={{ fontSize: '48px', color: '#c57c0e' }} />
          )}
        <Box>
          <Typography
            sx={{
              fontSize: '1.125rem',
              fontWeight: '500'
            }}
          >
            {guest ? guest.name : t('preview.labels.guest')}
          </Typography>
          <Typography>{t('preview.labels.adult')}</Typography>
        </Box>
      </Box>
      {guest ? null : <ChevronRightIcon />}
    </Box>
  )
}
export default ListItem
