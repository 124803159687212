import React, { useEffect } from 'react'
import { Box, Stack, TextField } from '@mui/material'
import PersonIcon from '../../../../../assets/icons/person.svg'
import CardIcon from '../../../../../assets/icons/card.svg'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { t } from 'i18next'
import { Controller, DefaultValues, useForm } from 'react-hook-form'
import { FatButton } from '../../../../../components/fatButton/FatButton'
import { usePaymentContext } from '../../../../../contexts/PaymentContext'
import { useIMask } from 'react-imask'
import ConditionsCheckbox from '../../../components/ConditionsCheckbox'
import TextFieldNumber from '@/components/textfieldNumber/TextFieldNumber'
import { format } from 'date-fns'
import MorePaymentMethods from '@/pages/payment/sections/MorePaymentMethods'
import { dateFormatter } from '@/utils/dateFormatter.utils'
import Span from '@/components/span/Span'

interface CardFormInput {
  price?: number
  ccname: string
  cardnumber: string
  'exp-date': Date | null
  cvc?: string
  acceptConditions: boolean
}

export interface CardFormData {
  price: string
  ccname: string
  cardnumber: string
  'exp-date': string
  cvc?: string
}

const emptyValues: DefaultValues<CardFormInput> = {
  'exp-date': null,
  acceptConditions: false,
  cardnumber: '',
  ccname: '',
  cvc: '',
  price: undefined,
}

const CardForm = ({
  priceToPay,
  onSubmit,
  loading,
}: {
  priceToPay?: string
  onSubmit?: (data: CardFormData) => void
  loading?: boolean
}) => {
  const {
    state: { apiPaymentData, useValidCardIdIfExists },
    setUseValidCardId,
  } = usePaymentContext()

  const { cardExpirationDate, cardName, cardNumber, validCardId, emptyCVV } = apiPaymentData ?? {}

  const useValidCardId = !!(useValidCardIdIfExists && validCardId)

  const {
    ref,
    unmaskedValue,
    setValue: setMaskedValue,
  } = useIMask({
    mask: useValidCardId ? 'XXXX XXXX XXXX 0000' : '0000 0000 0000 0000',
    definitions: {
      X: {
        mask: '0',
        displayChar: '*',
      } as any,
    },
  })

  const defaultValues: DefaultValues<CardFormInput> = {
    'exp-date': null,
    acceptConditions: false,
    price: undefined,
    ...(useValidCardId && {
      cardnumber: `000000000000${cardNumber}`,
      ccname: cardName,
      'exp-date': cardExpirationDate,
      cvc: emptyCVV ? '' : '***',
    }),
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<CardFormInput>({
    defaultValues,
  })

  const currentPriceToPay = priceToPay || watch('price')

  const handleOnSubmit = handleSubmit((data) => {
    onSubmit?.({
      price: currentPriceToPay!.toString(),
      'exp-date': format(data['exp-date']!, 'MM/yy'),
      cardnumber: data.cardnumber,
      ccname: data.ccname,
      cvc: data.cvc,
    })
  })

  useEffect(() => {
    setValue('cardnumber', unmaskedValue)
  }, [unmaskedValue])

  const handleIntroduceNewCard = () => {
    setUseValidCardId(false)
    reset(emptyValues)
    setMaskedValue('')
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack gap="1rem" position="relative">
        <Stack gap="1rem">
          {!priceToPay && (
            <TextFieldNumber
              disabled={loading}
              InputProps={{
                startAdornment: (
                  <Box component="img" src={CardIcon} width="18px" marginRight=".5rem" />
                ),
              }}
              placeholder={t('payment.Amount_to_pay')}
              {...register('price', {
                required: `${t('validations.Required')}`,
              })}
              error={!!errors.price}
              helperText={errors.price?.message}
            />
          )}

          <TextField
            autoComplete="cc-name"
            disabled={loading || !!useValidCardId}
            InputProps={{
              startAdornment: (
                <Box component="img" src={PersonIcon} width="18px" marginRight=".5rem" />
              ),
            }}
            placeholder={t('payment.Cardholder_name')}
            {...register('ccname', {
              required: `${t('validations.Required')}`,
            })}
            error={!!errors.ccname}
            helperText={errors.ccname?.message}
          />
          <Controller
            control={control}
            name="cardnumber"
            rules={{
              required: `${t('validations.Required')}`,
              maxLength: {
                value: 16,
                message: t('validations.Invalid'),
              },
              minLength: {
                value: 16,
                message: t('validations.Invalid'),
              },
            }}
            render={({ fieldState: { error } }) => {
              return (
                <TextField
                  disabled={loading || !!useValidCardId}
                  autoComplete="cc-number"
                  InputProps={{
                    startAdornment: (
                      <Box component="img" src={CardIcon} width="18px" marginRight=".5rem" />
                    ),
                  }}
                  inputRef={ref}
                  defaultValue={defaultValues.cardnumber}
                  placeholder={t('payment.Card_number')}
                  error={!!error}
                  helperText={error?.message}
                />
              )
            }}
          />
          <Stack direction="row" gap="1rem">
            <Controller
              control={control}
              name="exp-date"
              rules={{
                required: `${t('validations.Required')}`,
                validate: (date) => {
                  if (dateFormatter.isDateValid(date)) {
                    return true
                  }
                  return `${t('validations.Invalid')}`
                },
              }}
              render={({ field: { value, onChange, ref } }) => {
                return (
                  <DesktopDatePicker
                    minDate={new Date()}
                    onChange={onChange}
                    views={['year', 'month']}
                    value={value}
                    inputFormat="MM/yy"
                    inputRef={ref}
                    disabled={!!useValidCardId}
                    renderInput={({ ...props }) => {
                      return (
                        <TextField
                          fullWidth
                          placeholder="MM/YY"
                          autoComplete="cc-exp"
                          {...props}
                          error={!!errors['exp-date']}
                          helperText={errors['exp-date']?.message}
                          disabled={loading || !!useValidCardId}
                        />
                      )
                    }}
                  />
                )
              }}
            />

            <TextField
              placeholder="CVC"
              fullWidth
              autoComplete="cc-csc"
              disabled={loading || (!!useValidCardId && !emptyCVV)}
              {...register('cvc', {
                required: `${t('validations.Required')}`,
              })}
              error={!!errors.cvc}
              helperText={errors.cvc?.message}
            />
          </Stack>
        </Stack>
        <Box>
          <ConditionsCheckbox
            error={errors.acceptConditions?.message}
            checkboxProps={{
              ...register('acceptConditions', {
                validate: (value) => (value ? true : `${t('validations.Required')}`),
              }),
            }}
            conditions={apiPaymentData!.rateConditions}
          />
        </Box>

        <MorePaymentMethods onIntroduceNewCard={handleIntroduceNewCard} />

        <FatButton type="submit" loading={loading}>
          <Span>
            {`${t('payment.Pay')} ${
              (currentPriceToPay || watch('price')?.toLocaleString()) ?? ''
            }€`}
          </Span>
        </FatButton>
      </Stack>
    </form>
  )
}

export default CardForm
