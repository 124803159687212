import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from '@/config/routes.config'
import ChannelValidation from '@/pages/payment/pages/channelValidation/ChannelValidation'
import PaymentHome from '@/pages/payment/pages/home/PaymentHome'
import PaymentAdvance from '@/pages/payment/pages/advance/PaymentAdvance'
import PaymentDeposit from '@/pages/payment/pages/deposit/PaymentDeposit'
import PaymentTotal from '@/pages/payment/pages/total/PaymentTotal'
import PaymentPartial from '@/pages/payment/pages/partial/PaymentPartial'
import NotFound from '@/pages/not-found/NotFound'
const Checkin = React.lazy(() => import('@/pages/checkin/Checkin'))
const Home = React.lazy(() => import('@/pages/home/Home'))
const Payment = React.lazy(() => import('@/pages/payment/Payment'))

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={APP_ROUTES.PAYMENT} element={<Payment />}>
        <Route path={APP_ROUTES.PAYMENT} element={<PaymentHome />} />
        <Route path={APP_ROUTES.PAYMENT_CHANNEL_VALIDATION} element={<ChannelValidation />} />
        <Route path={APP_ROUTES.PAYMENT_ADVANCE} element={<PaymentAdvance />} />
        <Route path={APP_ROUTES.PAYMENT_BAIL} element={<PaymentDeposit />} />
        <Route path={APP_ROUTES.PAYMENT_PARTIAL} element={<PaymentPartial />} />
        <Route path={APP_ROUTES.PAYMENT_TOTAL} element={<PaymentTotal />} />
      </Route>
      <Route path="/checkin" element={<Checkin />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Router
