import React, { useLayoutEffect } from 'react'
import PaymentForm from '../../sections/paymentForm/PaymentForm'
import { usePaymentContext } from '@/contexts/PaymentContext'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { APP_ROUTES } from '@/config/routes.config'
import { Stack, Typography } from '@mui/material'
import { t } from 'i18next'

const PaymentDeposit = () => {
  const {
    state: { apiPaymentData },
    setError,
  } = usePaymentContext()

  const { bailPrice, paidTypeBailId, bailPaid } = apiPaymentData ?? {}

  const { navigate } = useAppNavigate()

  useLayoutEffect(() => {
    if (bailPaid) {
      navigate({
        pathname: APP_ROUTES.PAYMENT,
        replace: true,
      })
    }
  }, [])

  return (
    <Stack gap="1rem" textAlign="center">
      <Typography>{t('payment.Deposit-description')}</Typography>
      <PaymentForm
        priceToPay={bailPrice}
        paidTypeId={paidTypeBailId!}
        onPaymentError={(e) => {
          setError({
            message: e.message,
          })
          navigate({
            pathname: APP_ROUTES.PAYMENT,
            replace: true,
          })
        }}
        onPaymentSuccess={() => {
          navigate({
            pathname: APP_ROUTES.PAYMENT,
            replace: true,
          })
        }}
      />
    </Stack>
  )
}

export default PaymentDeposit
