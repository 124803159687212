export const COLORS = {
  GREEN: '#96F9BA',
  BLUE: '#005AEB',
  BLUE_DARK: '#0349bb',
  LIGHT_GREEN: '#0EC5C5',
  BLUE_LIGHT_BACKGROUND: '#E2EDF8',
  BLUE_CARD_BACKGROUND: '#CCDAF0',
  SUCCESS: '#D1F0CC',
  SUCCESS_TEXT: '#03A700',
  WARNING: '#F0EACC',
  WARNING_TEXT: '#EB7100',
  WARNING_BACKGROUND: '#FFB74B',
  BACKGROUND_COLOR: '#f5f7f8',
  ERROR: '#d32f2f',
}
